'use client';

import { TextField, TextFieldProps } from '@mui/material';
import { useRouter } from 'next/navigation';
import React from 'react';
import IconButton, { IconButtonProps } from '../IconButton';
import { useNavbarActions } from '../NavBar/useNavbarState';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Flex, { FlexProps } from '../ui/Flex';

type SearchFormProps = FlexProps<
  'div',
  {
    InputWrapProps?: FlexProps;
    TextFieldProps?: TextFieldProps;
    SearchBtnProps?: Omit<IconButtonProps, 'icon'>;
    CloseBtnProps?: Omit<IconButtonProps, 'icon'>;
    hideClose?: boolean;
    hideSearch?: boolean;
    redirect?: string;
  }
>;

export default function SearchForm({
  TextFieldProps,
  InputWrapProps,
  SearchBtnProps,
  CloseBtnProps,
  hideClose = false,
  hideSearch = false,
  redirect = '/search',
  ...props
}: SearchFormProps) {
  const router = useRouter();
  const setSearch = useNavbarActions((s) => s.setSearch);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const inputValue = (e.currentTarget[0] as HTMLInputElement).value;
    if (!inputValue) return;

    e.preventDefault();
    setSearch(false);
    // console.log('search submitted');
    router.push(`${redirect}/?q=${inputValue}`);
  };

  return (
    <Flex direction="row-reverse" alignItems="center" {...props}>
      {!hideClose && (
        <Btn
          tabIndex={-1}
          EndIconProps={{ name: 'close', cx: { fontSize: 'h4' } }}
          variant="unstyled"
          aria-label="close-search"
          onClick={() => setSearch(false)}
          fontFamily="mono"
          style={{ textTransform: 'uppercase' }}
          {...CloseBtnProps}>
          Close
        </Btn>
      )}
      <Box as="form" noValidate onSubmit={handleSubmit} cx={{ width: 'full' }}>
        <Flex direction="row-reverse" columnGap="gutter" {...InputWrapProps}>
          <TextField variant="standard" placeholder="Search..." {...TextFieldProps} />
          {!hideSearch && (
            <IconButton
              tabIndex={-1}
              type="submit"
              icon="search"
              variant="unstyled"
              aria-label="search-submit"
              cx={{ fontSize: 'h4' }}
              {...SearchBtnProps}
            />
          )}
        </Flex>
      </Box>
    </Flex>
  );
}
