import { mergePropsClassName } from '@liquorice/utils';
import { Collapse, CollapseProps } from '@mui/material';
import Box, { BoxProps } from '../ui/Box';
import * as styles from './UnstyledAccordion.css';
import { useUnstyledAccordionItem } from './useUnstyledAccordion';

export type UnstyledAccordionItemBodyProps<C extends React.ElementType = 'div'> = BoxProps<
  C,
  {
    index: number;
    disableCollapse?: boolean;
    disablePadding?: boolean;
    CollapseProps?: CollapseProps;
    InnerProps?: BoxProps;
  }
>;

const UnstyledAccordionItemBody = ({
  children,
  index,
  InnerProps,
  disableCollapse,
  disablePadding = false,
  CollapseProps,
  ...props
}: UnstyledAccordionItemBodyProps) => {
  const { isActive } = useUnstyledAccordionItem(index);

  if (disableCollapse) return <Box {...props}>{children}</Box>;

  return (
    <Box {...props}>
      <Collapse
        timeout={{
          appear: 500,
          enter: 300,
          exit: 300,
        }}
        // onExited={() => !isActive}
        // onEntering={() => isActive}
        // onEntered={() => isActive}
        className={styles.panel}
        in={isActive}
        aria-expanded={isActive ? 'true' : 'false'}
        {...CollapseProps}>
        <Box {...mergePropsClassName(InnerProps, styles.panelInner({ padding: !disablePadding }))}>
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default UnstyledAccordionItemBody;
